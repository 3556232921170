<template>
    <div>
    <div class="sub-title">全区设施农业主要播种品种面积与产量统计分析</div>
        <el-button type="primary" style="min-width: 88px;" @click="add">新增</el-button>
        <el-table border :data="tableData" class="mt20">
          <el-table-column prop="cerealsName" label="品种"></el-table-column>
          <el-table-column prop="onYearArea" label="上年度面积"></el-table-column>
          <el-table-column prop="thisYearArea" label="上年度产量"></el-table-column>
          <el-table-column prop="onYearOutput" label="本年度面积"></el-table-column>
          <el-table-column prop="thisYearOutput" label="本年度产量"></el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)" size="small">修改</el-button>
              <el-button type="text" @click="remove(scope.row.id)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
       
        <div style="overflow: hidden;">
            <div style="margin-top: 12px; float: right">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="title" class="pop" width="500px" :visible.sync="dialog">
            <el-form  label-width="120px">
                <el-form-item label="品种" >
                  <el-input v-model="form.cerealsName" autocomplete="off"></el-input>
                </el-form-item>
              <el-form-item label="上年度面积" >
                <el-input v-model="form.onYearArea" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="本年度面积" >
                <el-input v-model="form.thisYearArea" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="上年度产量" >
                <el-input v-model="form.onYearOutput" autocomplete="off"></el-input>
              </el-form-item>
                <el-form-item label="本年度产量">
                  <el-input v-model="form.thisYearOutput" autocomplete="off"></el-input>
                </el-form-item>
                <div class="dialog-footer" style="text-align: right">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            title: '新增',
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            tableData: [],
            dialog: false,
            form: {
              cerealsName: '',
              onYearArea: '',
              thisYearArea: '',
              onYearOutput: '',
              thisYearOutput: '',
            }
        }
    },

    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            //查询列表
            this.qa.datascreen17AllAreaSowingStatisticsQueryForPageList(this.pageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                }
            })
        },
        handleSizeChange(page){
            this.pageInfo.pageSize = page;
            this.getInfo()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.getInfo()
        },
        edit(row) {
            this.title = '修改'
            this.form = JSON.parse(JSON.stringify(row))
            this.dialog = true
        },
        add() {
            this.form = {
              cerealsName: '',
              onYearArea: '',
              thisYearArea: '',
              onYearOutput: '',
              thisYearOutput: '',
            }
            this.dialog = true
        },

        addSubmit() {
            if(this.title == '新增') {
                this.qa.datascreen17AllAreaSowingStatisticsDoInsert(this.form).then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.getInfo()
                })
            }else {
                //修改
                this.qa.datascreen17AllAreaSowingStatisticsDoUpdate(this.form).then(res => {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.getInfo()
                })
            }
        },
        remove(id) {
            this.qa.datascreen17AllAreaSowingStatisticsDoBatchDelete({id: id}).then(res => {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getInfo()
            })
        },
        handleAvatarSuccess(res) {
            this.form.url = res.data.url
        },
        beforeAvatarUploadPice(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 10;
            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 10MB!');
            }
            return  isLt2M;
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    
    .pop >>> .el-time-spinner {
        box-sizing: border-box;
        padding-left: 20px;
    }
    .avatar-uploader {
        width: 150px;
        height: 162px;
        border: 1px dashed #ccc;
        text-align: center;
    }
    .avatar-uploader .avatar {
        width: 150px;
        height: 162px;
    }
</style>