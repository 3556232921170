<template>
    <div>
        <div class="sub-title">基础信息</div>
        <el-form :inline="true" label-width="180px" :model="form" class="demo-form-inline">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="总面积(亩)">
                        <el-input v-model="form.totalArea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="温室数量(座)">
                        <el-input v-model="form.greenhousesNumber"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="温室面积(亩)">
                        <el-input v-model="form.greenhousesArea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="拱棚数量（座）">
                        <el-input v-model="form.archesNumber"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="拱棚面积（亩）">
                        <el-input v-model="form.archesArea"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="6">
                    <el-form-item label="黄色区域名称">
                        <el-input v-model="form.areaName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="黄色区域总面积(亩)">
                        <el-input v-model="form.yellowTotalArea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="黄色区域温室数量(座)">
                        <el-input v-model="form.yellowGreenhousesNumber"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="黄色区域拱棚数量(座)">
                        <el-input v-model="form.yellowArchesNumber"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="ta_c">
                <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
            </el-row>
        </el-form>  
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            form: {
                mp4: '',
            },
            posilist: [
                {key: 1, value: '左'},
                {key: 2, value: '右'},
            ],
            title: '新增',
            dialog: false,
            formData: {},
            tableData: []
        }
    },
    
    mounted(){
        this.search();
        this.getInfo()
    },
    methods:{
        search(){
            this.qa.datascreen15queryForList({}).then(res => {
                this.tableData = res.data;
            })
        },
        getInfo() {
            this.qa.datascreen17QueryUniqueObject({}).then(res => {
                if(res.respCode == '0000') {
                    this.form = res.data;
                }
            })
        },
        add(){
            this.formData = {};
            this.title = "新增";
            this.dialog = true;
        },
        edit(row){
            this.formData = JSON.parse(JSON.stringify(row));
            this.title = "修改";
            this.dialog = true;
        },

        remove(id){
            this.qa.datascreen15DelCompany({id: id}).then(res => {
                this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    // this.dialog = false;
                    this.search()
            })
        },

        addSubmit() {
            if(this.title == '新增') {
                this.qa.datascreen15AddCompany(this.formData).then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.search()
                })
            }else {
                //修改
                this.qa.datascreen15EditCompany(this.formData).then(res => {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.search()
                })
            }
        },

        onSaveInfo() {
            this.qa.datascreen17DoUpdate(this.form).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getInfo()
                }
            })
            
        },
        beforeAvatarUpload(file) {
            console.log(file.type)
            //const isJPG = file.type === 'image/jpeg';
            //const isLt2M = file.size / 1024 / 1024 < 10;

            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 mp4 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 10MB!');
            // }
            // return  isLt2M;
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.form.mp4 = res.data.url
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .avatar-uploader {
        width: 150px;
        height: 162px;
        border: 1px dashed #ccc;
        text-align: center;
    }
    .avatar-uploader .avatar {
        width: 150px;
        height: 162px;
    }
    .line {
        width: 100%;
        height: 1px;
        margin: 30px 0;
        background: #ccc;
    }
</style>