<template>
    <div>
    <div class="sub-title">全区设施农业主要播种品种价格区间</div>
        <el-button type="primary" style="min-width: 88px;"  @click="add">新增</el-button>
        <el-table border :data="tableData" class="mt20">
          <el-table-column prop="cerealsName" label="品种"></el-table-column>
          <el-table-column prop="highestPrice" label="价格最高"></el-table-column>
          <el-table-column prop="lowestPrice" label="价格最低"></el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)" size="small">修改</el-button>
              <el-button type="text" @click="remove(scope.row.id)" size="small">删除</el-button>
            </template>
          </el-table-column>

        </el-table>
        <div style="overflow: hidden;">
            <div style="margin-top: 12px; float: right">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="title" class="pop" width="500px" :visible.sync="dialog">
            <el-form  label-width="150px">
                <el-form-item label="品种" >
                  <el-input v-model="form.cerealsName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="价格最高">
                  <el-input type="number" v-model="form.highestPrice" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="价格最低">
                  <el-input type="number" v-model="form.lowestPrice" autocomplete="off"></el-input>
                </el-form-item>
                <div class="dialog-footer" style="text-align: right">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            title: '新增',
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            tableData: [],
            dialog: false,
            form: {
              cerealsName: '',
              highestPrice: '',
              lowestPrice: '',
            }
        }
    },

    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            //查询
            this.qa.datascreen17AllAreaSowingPriceQueryForPageList(this.pageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                }
            })
        },
        handleSizeChange(page){
            this.pageInfo.pageSize = page;
            this.getInfo()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.getInfo()
        },
        edit(row) {
            this.title = '修改'
            this.form = JSON.parse(JSON.stringify(row))
            this.dialog = true
        },
        add() {
            this.title = '新增'
            this.form = {
              cerealsName: '',
              highestPrice: '',
              lowestPrice: '',
            }
            this.dialog = true
        },
        addSubmit() {
            if(this.title == '新增') {
              this.qa.datascreen17AllAreaSowingPriceDoInsert(this.form).then(res => {
                this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                this.dialog = false;
                this.getInfo()
              })
            }else {
                //修改
              this.qa.datascreen17AllAreaSowingPriceDoUpdate(this.form).then(res => {
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
                this.dialog = false;
                this.getInfo()
              })
            }
        },
        remove(id) {
          this.qa.datascreen17AllAreaSowingPriceDoBatchDelete({id: id}).then(res => {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getInfo()
          })
        }
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    
    .pop >>> .el-time-spinner {
        box-sizing: border-box;
        padding-left: 20px;
    }
</style>